<template>
  <sun-template
    :country-of-residence="countryOfResidence"
    :city-of-residence="cityOfResidence"
    :planned-high-u-v-exposure="plannedHighUVExposure"
    :temperature-contrast="temperatureContrast"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import SunTemplate from '@/modules/questionnaire/components/steps/common/oxidative-stress/sun/SunTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { equalsAny } from '@/modules/questionnaire/api/helpers';

import { types } from '@/modules/questionnaire/store/types';
import { TEMPERATURE } from '@/modules/questionnaire/api/constants';

const { requiredField, field } = fieldBuilder;

const FIELDS = [
  requiredField('cityOfResidence'),
  requiredField('countryOfResidence'),
  requiredField('plannedHighUVExposure'),
  field('temperature')
];

export default {
  name: 'Sun',
  components: { SunTemplate },
  mixins: [makeStep(FIELDS)],
  computed: {
    ...mapGetters({
      doctor: types.getters.GET_DOCTOR
    }),
    temperatureContrast() {
      return equalsAny(this.temperature, [
        TEMPERATURE.FREEZING,
        TEMPERATURE.VERY_COLD,
        TEMPERATURE.COLD,
        TEMPERATURE.COOL,
        TEMPERATURE.MILD
      ]);
    }
  },
  watch: {
    countryOfResidence() {
      this.cityOfResidence = '';
      this.plannedHighUVExposure = '';
    },
    async cityOfResidence(newValue) {
      this.temperature = '';
      this.plannedHighUVExposure = '';

      if (newValue && this.countryOfResidence) {
        await this.getTemperature();

        if (!this.temperatureContrast) {
          this.showNextStep();
        } else {
          this.scrollTo('#uv-exposure');
        }
      }
    },
    plannedHighUVExposure(newValue, oldValue) {
      if (!oldValue) {
        this.showNextStep();
      }
    }
  },
  mounted() {
    if (!this.countryOfResidence) {
      this.countryOfResidence = this.doctor.country.toLowerCase();
    }
  },
  methods: {
    ...mapActions({
      fetchTemperature: types.actions.FETCH_TEMPERATURE
    }),
    getTemperature: debounce(
      async function getTemperatureCallback() {
        await this.fetchTemperature({
          country: this.countryOfResidence,
          town: this.cityOfResidence
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
    isFieldVisible(fieldName) {
      if (fieldName === 'plannedHighUVExposure') {
        return this.temperatureContrast;
      }

      return true;
    }
  }
};
</script>
